import React, { createContext, useState, useEffect } from "react";
import { getFromLocalStorage, setToLocalStorage } from "../utils/localStorage";
import { loginAPI } from "../api/auth";

export const AuthContext = createContext();

const AuthStateProvider = ({ children }) => {
  const [user, setUser] = useState(getFromLocalStorage("user") || null);
  const [authToken, setAuthToken] = useState(
    getFromLocalStorage("authToken") || null
  );
  //   const [user, setUser] = useState(null);
  //   const [authToken, setAuthToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const login = async (credentials) => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const data = (await loginAPI(credentials)).data;
      setUser(data.user);
      setAuthToken(data.token);
      setToLocalStorage("user", data.user);
      if (data.user.role === "community_admin") {
        setToLocalStorage("community", data.user);
      }
      setToLocalStorage("authToken", data.token);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    setAuthToken(null);
    setToLocalStorage("user", null);
    setToLocalStorage("authToken", null);
  };

  useEffect(() => {
    const checkLocalStorage = () => {
      const userFromStorage = getFromLocalStorage("user");
      const authTokenFromStorage = getFromLocalStorage("authToken");
      if (userFromStorage && authTokenFromStorage) {
        setUser(userFromStorage);
        setAuthToken(authTokenFromStorage);
      }
    };

    checkLocalStorage();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        authToken,
        isLoading,
        errorMessage,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthStateProvider;
