import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/images/orrrg-logo.png";
import { AuthContext } from "../context/AuthContext";
import { getFromLocalStorage } from "../utils/localStorage";
import { Link, useParams } from "react-router-dom";
import MenuSVG from "../assets/images/menu.svg";
import UserProfileSVG from "../assets/images/user-profile.svg";
import LogoutSVG from "../assets/images/logout.svg";

const Topbar = () => {
  const { logout, authToken } = useContext(AuthContext);
  const [community, setCommunity] = useState(null);
  const { community_slug } = useParams();
  useEffect(() => {
    const communityData = getFromLocalStorage("community");
    setCommunity(communityData);
  }, []);
  return (
    <div className="bg-orgGray py-4">
      <div className="container flex justify-between items-center">
        {authToken && <label for="my-drawer-2" class=" drawer-button lg:hidden">
          <img src={MenuSVG} alt="Search" className="h-10" />
        </label>}
        {community_slug && community && community.name ? (
          <div className="text-xl lg:text-3xl font-semibold">{community.name}</div>
        ) : (
          <div>&nbsp;</div>
        )}

        <img src={Logo} className="w-24 lg:w-36" />
        {authToken ? (
          <div className="flex">
            {community_slug && community && community.name && (
              <Link
                to={`${community.slug}/manage-account`}
                className="btn text-gray-400 text-xl btn-ghost px-0 lg:px-3"
              >
                <span className="hidden lg:inline-block">Manage Account</span>
                <img
                  src={UserProfileSVG}
                  alt="Search"
                  className="h-8 lg:hidden"
                />
              </Link>
            )}
            <button
              className="btn text-primary lg:text-xl btn-ghost"
              onClick={logout}
            >
              <span className="hidden lg:inline-block">Sign Out</span>
              <img
                  src={LogoutSVG}
                  alt="Search"
                  className="h-8 lg:hidden"
                />
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Topbar;
