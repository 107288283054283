import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../context/AuthContext";
import { update } from "../api/user";
import { setToLocalStorage } from "../utils/localStorage";
import { useParams } from "react-router-dom";

const ManageAccount = ({ initialValues, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [community, setCommunity] = useState(null);
  const { user } = useContext(AuthContext);
  const { community_slug } = useParams();

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Community name is required")
      .min(3, "Community name must be at least 3 characters")
      .max(20, "Community name must be at most 20 characters"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    url: Yup.string()
      .required("URL is required")
      .min(3, "URL must be at least 3 characters")
      .max(20, "URL must be at most 20 characters"),
    total_member: Yup.number()
      .min(
        data?.count ?? 1,
        `Total number must be greater than or equal to ${data?.count ?? 1}`
      )
      .max(10000)
      .required("Total number is required"),
  });

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await update({ community_slug, values });
      if (response.success === true) {
        console.log(response.data);
        await setToLocalStorage("community", response.data);
        setTimeout(() => {
          window.location = `http://${process.env.REACT_APP_FRONT_URL}${response.data.slug}/manage-account`;
        }, 2000);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <div className="max-w-xl mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <h2 className="text-3xl text-center mb-10">Manage Account</h2>

              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Community Name
                </label>
                <Field
                  type="text"
                  name="name"
                  id="name"
                  className="input input-bordered"
                />
                <ErrorMessage name="name" className="error" component="div" />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="username"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Email
                </label>
                <Field
                  type="text"
                  name="email"
                  id="email"
                  className="input input-bordered"
                />
                <ErrorMessage name="email" className="error" component="div" />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  URL
                </label>
                <div className="join items-center input input-bordered">
                  <div className="join-item">
                    {process.env.REACT_APP_FRONT_URL}
                  </div>
                  <div className="join-item">
                    <Field type="text" name="url" id="url" className="" />
                  </div>
                </div>
                <ErrorMessage name="url" className="error" component="div" />
              </div>
              {user.role == "admin" && (
                <div className="mb-4">
                  <label
                    htmlFor="total_member"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Total members
                  </label>

                  <div className="join items-center input input-bordered pr-0">
                    <div className="join-item w-full">
                      <Field
                        type="number"
                        min={1}
                        step={1}
                        name="total_member"
                        id="total_member"
                        className="w-full"
                        onKeyPress={(e) => {
                          if (e.code === "Minus" || e.code == "Equal") {
                            e.preventDefault();
                          } else if (e.charCode < 48) {
                            e.preventDefault();
                          } else if (values.total_member < 2) {
                            e.preventDefault();
                          }
                        }}
                        onKeyUp={(e) => {
                          if (values.total_member < 2) {
                            values.total_member = 2;
                          }
                        }}
                      />
                    </div>
                    <button
                      className="btn join-item text-2xl"
                      type="button"
                      onClick={() =>
                        setFieldValue("total_member", values.total_member + 1)
                      }
                    >
                      +
                    </button>
                    <button
                      className="btn join-item text-2xl"
                      type="button"
                      onClick={() =>
                        setFieldValue(
                          "total_member",
                          values.total_member > 2
                            ? values.total_member - 1
                            : null
                        )
                      }
                    >
                      -
                    </button>
                  </div>

                  <ErrorMessage
                    name="total_member"
                    className="error"
                    component="div"
                  />
                </div>
              )}

              <div className={`flex items-center justify-end`}>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading} // Disable button while loading
                >
                  {isLoading ? "Please wait..." : "Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ManageAccount;
