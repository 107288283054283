import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AlertProvider from "./context/AlertContext";
import AuthStateProvider from "./context/AuthContext";
import AuthLayout from "./layout/AuthLayout";
import Layout from "./layout/Layout";
import CommunityLayout from "./layout/CommunityLayout";

import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

import Communities from "./pages/Communities";

import Members from "./pages/Community/Members";
import Messages from "./pages/Community/Messages";
import Message from "./pages/Community/Message";

import Requests from "./pages/Community/Requests";

import Channels from "./pages/Community/Channels";
import Posts from "./pages/Community/Posts";
import Deleted from "./pages/Community/Deleted";
import ManageAccount from "./pages/Community/ManageAccount";

function App() {
  return (
    <AlertProvider>
      <AuthStateProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthLayout />}>
              <Route index element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password/:token" element={<ResetPassword />} />
            </Route>
            <Route path="/" element={<Layout />}>
              <Route index path="communities" element={<Communities />} />
            </Route>
            <Route path="/" element={<CommunityLayout />}>
              <Route
                index
                path=":community_slug/members"
                element={<Members />}
              />
              <Route
                path=":community_slug/members/:member_id/messages"
                element={<Messages />}
              />
              <Route
                path=":community_slug/members/:member_id/message/:receiver_id"
                element={<Message />}
              />

              <Route path=":community_slug/requests" element={<Requests />} />

              <Route path=":community_slug/channels" element={<Channels />} />

              <Route path=":community_slug/posts" element={<Posts />} />

              <Route path=":community_slug/deleted" element={<Deleted />} />

              <Route
                path=":community_slug/manage-account"
                element={<ManageAccount />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthStateProvider>
    </AlertProvider>
  );
}

export default App;
